import * as StringHelpers from './StringHelpers';
import {
  TYPE_INTEGRATION_AXIUM,
  TYPE_INTEGRATION_CLOUD_9,
  TYPE_INTEGRATION_DOLPHIN,
  TYPE_INTEGRATION_EPIC,
  TYPE_INTEGRATION_MI7,
  TYPE_INTEGRATION_NEXTECH,
  TYPE_INTEGRATION_PROGNOCIS,
  TYPE_PATIENT,
} from '../constants/Types';

export function formatName(organization) {
  return organization.split(' ').map((j) => StringHelpers.capitalize(j)).join(' ');
}

// write back by default enabled for Nextech integration service.
export function isWriteBackEnabled(organization) {
  return organization.isDocumentWriteBackEnabled || [TYPE_INTEGRATION_NEXTECH].includes(organization.integrationPartnerTypeId);
}

export function canSendToEHR(organization, activeUser) {
  const integrationMustBePatient = [
    TYPE_INTEGRATION_AXIUM,
    TYPE_INTEGRATION_CLOUD_9,
    TYPE_INTEGRATION_DOLPHIN,
    TYPE_INTEGRATION_EPIC,
    TYPE_INTEGRATION_MI7,
    TYPE_INTEGRATION_PROGNOCIS,
  ].includes(organization.integrationPartnerTypeId);
  const isPatient = activeUser.typeId === TYPE_PATIENT;
  const integrationRequiresPatientOfficeId = [TYPE_INTEGRATION_MI7].includes(organization.integrationPartnerTypeId);
  return (
    activeUser.integrated &&
    isWriteBackEnabled(organization) &&
    (!integrationMustBePatient || isPatient) &&
    (!integrationRequiresPatientOfficeId || !!activeUser.officeId)
  );
}

export const FORMS_LIST_HEADERS_CONFIG = {
  title: {
    default: true,
    direction: 1,
  },
  updatedAt: {
    default: false,
    direction: -1,
  },
};

export const TEMPLATES_LIST_HEADERS_CONFIG = {
  subject: {
    default: true,
    direction: 1,
  },
  message: {
    default: false,
    direction: -1,
  },
  lastUpdatedAt: {
    default: false,
    direction: -1,
  },
  type: {
    default: false,
    direction: -1,
  },
  attachment: {
    default: false,
    direction: -1,
  },
};

export const NAVIGATION_TABS = {
  templates: 1,
  forms: 2,
  savedContent: 3,
};
